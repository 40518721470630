/* You can add global styles to this file, and also import other style files */
// Need this for apollo-mat-core, apollo-material-theme, and other common styles
@import "~@angular/material/theming";
@import "~@elemica/common/scss/styles";
// @import "~@elemica/common/scss/app-variables";
// @import "~@elemica/common/scss/app-common";
// Import common styles
// @import "app-variables";
// @import "app-common";

// A wrapper for mat-core injecting apollo typography
@include apollo-mat-core();

/* Themes */
@import "~@elemica/common/scss/themes/default-theme";

html, body { height: 100%;  font-family: 'Open Sans', sans-serif; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// .default-theme {
    @include apollo-material-theme($elemica-theme);
    // @include custom-components-theme($elemica-theme);
//   } 
  